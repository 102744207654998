import { objectService } from '@/modules/common/api';
import { apiClient } from '@/provider';
import { prepareObject } from '@/provider/utils';
import objectScheme from './object.graphql';
import objectsFromDevicesScheme from './object-from-devices.graphql';
export default {
  ...objectService,
  async fetch(id) {
    const res = await apiClient.query({
      query: objectScheme.fetch,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    return prepareObject(res.data.object);
  },

  update(id, { name, description, enabled }) {
    return apiClient.mutate({
      mutation: objectScheme.update,
      variables: {
        id,
        name,
        description,
        enabled
      }
    });
  },

  setFavourite(id, favourite) {
    return apiClient.mutate({
      mutation: objectScheme.setFavourite,
      variables: {
        id,
        favourite
      }
    });
  },

  async getCountDevices(schemaId, name, enabled) {
    const res = await apiClient.query({
      query: objectsFromDevicesScheme.countDevices,
      variables: {
        schemaId,
        name,
        enabled
      },
      fetchPolicy: 'network-only'
    });

    return res.data?.objectsConnection?.totalCount;
  }
};
